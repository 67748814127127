<template>
	<div>
		<div class="page--service container container-l mx-auto">
			<section>
				<hero :title="service.title" :text="service.discription">
					<img class="mx-auto" :src="service.main_media" alt="" />
				</hero>
			</section>
			<section v-if="service.tecnologies.length" class="tecnology-section">
				<h2 class="title text-2xl sm:text-4xl xl:text-6xl">
					{{ $t("headings.usedTechnologies") }}
				</h2>
				<div>
					<tecnologies :tecnologies="service.tecnologies" />
				</div>
			</section>
			<div class="flex justify-center">
				<button class="btn is-main nav-btn">
					<router-link to="/contact-us">
						Get Quotation Now
					</router-link>
				</button>
			</div>
		</div>
	</div>
</template>
<script>
import Tecnologies from "../components/Tecnologies.vue";
import Hero from "../components/Hero.vue";

export default {
	components: {
		Tecnologies,
		Hero
	},

	data() {
		return {
			service: {
				title: "Design",
				discription:
					"We offer unique designs based on analyzing the project in all its aspects.",
				main_media: "images/Design.svg",
				tecnologies: [
					{ id: 3, image: "images/aftereffect.png", name: "After Effect" },
					{ id: 4, image: "images/Sketchpng.png", name: "Sketch" },
					{ id: 5, image: "images/Adobe XDpng.png", name: "Adobe XD" },
					{ id: 6, image: "images/Photoshop.jpg", name: "Photoshop" },
					{ id: 7, image: "images/Illustrator.png", name: "Illustrator" },
					{ id: 8, image: "images/InDesign.png", name: "InDesign" }
				]
			},
			selectedSection: null,
			chunk: [],
			chunks: []
		};
	}
};
</script>
