<template>
  <section class="2xl:pl-8">
    <div class="container container-l flex mx-auto mb-11">
      <section class="xs:flex justify-center flex flex-wrap-reverse w-full">
        <div
          class="sm:w-1/2 flex-grow md:w-5/12 text-xl font-medium flex flex-col justify-center px-6 md:text-3xl my-11"
        >
          <h2 v-if="title" class="text-6xl">{{ title }}</h2>
          <h2 :class="title ? 'text-xl lg:text-2xl' : ''">
            {{ text }}
          </h2>
          <button
            class="mt-20 text-left text-xl font-semibold text-primary-600 flex items-center"
            v-if="buttonText !== null"
          >
            {{ buttonText }}

            <font-awesome-icon
              :icon="
                $i18n.locale === 'ar'
                  ? ['fas', 'arrow-left']
                  : ['fas', 'arrow-right']
              "
              size="2x"
              class="mx-6"
            />
          </button>
        </div>

        <div class="w-2/3 sm:w-1/2 flex" :class="{ 'order-first': reverse }">
          <slot></slot>
        </div>
      </section>
    </div>
  </section>
</template>
<script>
export default {
  props: {
    text: {
      required: true
    },
    title: {
      default: ""
    },

    buttonText: {
      default: null
    },
    reverse: {
      default: false
    }
  }
};
</script>
