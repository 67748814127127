<template>
	<div>
		<div>
			<div
				class="tecnology-container hidden xs:flex"
				v-for="(chunk, index) in chunks"
				:key="index"
			>
				<div class="tecnology" v-for="tecnology in chunk" :key="tecnology.id">
					<figure class="w-20 h-20s">
						<img class="w-fulls w-auto h-full" :src="tecnology.image" alt="" />
						<!-- <img class="" src="@/assets/images/tec1.svg" alt="" /> -->
					</figure>
					<span>{{ tecnology.name }}</span>
				</div>
			</div>
			<div class="tecnology-container flex xs:hidden">
				<div
					class="tecnology"
					v-for="tecnology in tecnologies"
					:key="tecnology.id"
				>
					<figure class="w-28">
						<img class="w-fulls w-auto h-full" :src="tecnology.image" alt="" />
						<!-- <img class="" src="@/assets/images/tec1.svg" alt="" /> -->
					</figure>
					<span>{{ tecnology.name }}</span>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
export default {
	props: ["tecnologies"],
	data() {
		return {
			chunk: [],
			chunks: []
		};
	},
	mounted() {
		console.log(this.tecnologies);
		this.setTecnologies();
	},
	methods: {
		setTecnologies() {
			let num = 6;
			this.tecnologies.map((tec, index) => {
				this.chunk.push(tec);
				if (this.chunk.length === num) {
					this.chunks.push(this.chunk);
					this.chunk = [];
					num -= 1;
				} else {
					if (this.tecnologies.length - index === 1) {
						this.chunks.push(this.chunk);
					}
				}
			});
		}
	}
};
</script>
